<template>
	<div id="myQuizSystem" v-loading="loading" :element-loading-text="loadingText"
		element-loading-background="rgba(0, 0, 0, 0)">
		<exam-nav></exam-nav>
		<div class="mainContent">
			<!-- 已答/未答的tab -->
			<div class="myTabs">
				<el-tabs v-model="completed" @tab-click="tabhandleClick(completed)">
					<el-tab-pane label="未答" name="first"></el-tab-pane>
					<el-tab-pane label="已答" name="second"></el-tab-pane>
				</el-tabs>
			</div>
			<!-- 查询 -->
			<div class="search">
				<el-input v-model="keyword" placeholder="请输入试卷名称关键字" @input="keywordValue(keyword)"></el-input>
				<el-button size="small" class="searchButton" @click="searchClick">查询</el-button>
			</div>
			<!-- 列表 -->
			<div class="quizListTable">
				<el-table :cell-style="rowClass" ref="multipleTable" :header-cell-style="headClass"
					:data="quizSystemData" border stripe :key="Math.random()">
					<template slot="empty">
						<span v-show="loading">加载中...</span>
						<span v-show="!loading">暂无数据</span>
					</template>
					<el-table-column type="index" :index="indexMethod" label="#" width="60"></el-table-column>
					<el-table-column prop="category" label="试卷标题" :show-overflow-tooltip="true" width="180">
						<template scope="scope">{{scope.row.category || '--'}}</template>
					</el-table-column>
					<el-table-column prop="minutesLimit" label="限时（分钟）" :show-overflow-tooltip="true">
						<template scope="scope">{{scope.row.minutesLimit || '--'}}</template>
					</el-table-column>
					<el-table-column prop="fromTime" label="起始时间" v-if="completed=='first'"
						:show-overflow-tooltip="true">
						<template scope="scope">{{scope.row.fromTime || '--'}}</template>
					</el-table-column>
					<el-table-column prop="toTime" label="截止时间" v-if="completed=='first'" :show-overflow-tooltip="true">
						<template scope="scope">{{scope.row.toTime || '--'}}</template>
					</el-table-column>
					<el-table-column prop="startTime" label="开始答题" :show-overflow-tooltip="true">
						<template scope="scope">{{scope.row.startTime || '--'}}</template>
					</el-table-column>
					<el-table-column prop="endTime" label="结束答题" :show-overflow-tooltip="true">
						<template scope="scope">{{scope.row.endTime || '--'}}</template>
					</el-table-column>
					<el-table-column prop="allScore" v-if="completed=='second'" label="分数" :show-overflow-tooltip="true" width="100">
						<template scope="scope">
							<span v-if="scope.row.allScore || scope.row.allScore===0">{{scope.row.allScore}}</span>
							<span v-else>--</span>
						</template>
					</el-table-column>
					<el-table-column label="操作" fixed="right" width="120">
						<template slot-scope="scope">
							<el-button type="infor" size="mini" v-if="completed=='first' && (!scope.row.startQuiz)"
								:disabled="true">{{scope.row.endTitle}}</el-button>
							<el-button type="primary" size="mini" v-if="completed=='first' && (scope.row.startQuiz)"
								@click="editClick(scope.$index,scope.row)">{{scope.row.startTitle}}</el-button>
							<el-button type="primary" size="mini" v-if="completed=='second'"
								@click="editClick(scope.$index,scope.row)">
								答题详情</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<!-- 答题弹框 -->
			<el-dialog title="答题框" :visible.sync="dialogForm" :close-on-click-modal="false" class="editStyle">
				<exam-form ref="examForm" v-on:formBtn='getFormValue($event)'></exam-form>
				<!-- <span slot="footer" class="dialog-footer"></span> -->
			</el-dialog>
			<!-- 分页 -->
			<div class="block">
				<el-pagination layout="prev, pager, next" :total="total" :current-page.sync="currentPage"
					:page-size="pageSize"></el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import Cookies from 'js-cookie';
	import ExamNav from './nav.vue';
	import Form from './form.vue';
	export default {
		name: 'QuizSystem',
		components: {
			"exam-nav": ExamNav,
			"exam-form": Form,
		},
		data() {
			return {
				loading: false, //加载中
				loadingText: "",
				submitProcessing: false, //只能点击一次
				// 查询框内容
				completed: "first",
				keyword: "",
				// 考试范围数组
				scopeData: [],
				// 查询对应的key
				keywordKey: "",
				// 列表
				quizSystemData: [],
				// 分页
				currentPage: 1,
				total: 0,
				pageNum: 1,
				pageSize: 20,
				// 弹框
				dialogForm: false,
				userId:'',
				// 初次请求
				firsRequest:false,
			}
		},
		mounted() {
			this.getquizSystemData();
		},
		watch: {
			// 监听点击分页
			currentPage(oldV, newV) {
				var that = this;
				that.handleCurrentChange(oldV);
			},
			// 监听弹框关闭，请求列表
			dialogForm(oldV, newV) {
				var that = this;
				if (!oldV) {
					that.handleCurrentChange(that.pageNum);
				}
			}
		},
		methods: {
			// 获取userId
			getMeData() {
				let that = this;
				that.$axios
					.get("/me")
					.then(function(res) {
						that.userId=res.data.user.id;
					})
					.catch(function(err) {
					});
			},
			getFormValue(val){
				console.log(val);
				this.dialogForm = false;
			},
			// tab切换
			tabhandleClick(val) {
				var that = this;
				that.keyword = "";
				that.keywordKey = "";
				that.pageNum= 1;
				that.getquizSystemData();
			},
			// 查询时关键字不能为空
			keywordValue(val) {
				this.keyword = val.replace(/\s+/g, "");
			},
			//表格样式
			headClass() {
				return "text-align: center;background:#D7A24A; color:#ffffff";
			},
			rowClass() {
				return "text-align: center;";
			},
			indexMethod(index) {
				return (this.pageNum - 1) * this.pageSize + index + 1;
			},
			// 查询
			searchClick() {
				var that = this;
				if (navigator.onLine == true) {
					that.keywordKey = that.keyword;
					that.pageNum = 1;
					that.currentPage = 1;
					that.getquizSystemData();
				} else {
					that.$alert('请检查当前网络!', '提示', {
						confirmButtonText: '确定',
						type: 'error',
						callback: action => {}
					});
				}
			},
			// 分页--页码的切换
			handleCurrentChange(oldV) {
				var that = this;
				that.dataList = [];
				that.pageNum = oldV;
				that.getquizSystemData();
			},
			// 获取列表data
			getquizSystemData() {
				var that = this;
				that.quizSystemData = [];
				that.loading = true;
				that.$axios
					.post("/category/list", {
						completed: that.completed == "first" ? false : true,
						keyword: that.keywordKey,
						pageIndex: that.pageNum - 1 < 0 ? 0 : that.pageNum - 1,
						pageSize: that.pageSize,
					}, {
						headers: {
							'exam': 'true'
						}
					})
					.then(function(res) {
						if(!that.firsRequest){
							that.getMeData();
						}
						that.firsRequest=true;
						that.loading = false;
						that.quizSystemData = res.data.data;
						that.quizSystemData.forEach(item => {
							console.log(item);
							item.allScore=Number(item.score?item.score:0)+Number(item.scoreExtra?item.scoreExtra:0);
							var nowTime = new Date().getTime()
							// 开始时间
							if(item.fromTs){
								item.fromTime = that.COMMON.getDataMethods('getTime', new Date(item.fromTs));
							}
							if(item.toTs){
								item.toTime = that.COMMON.getDataMethods('getTime', new Date(item.toTs));
							}
							// 开始答题时间
							if (item.startTs) {
								item.startTime = that.COMMON.getDataMethods('getTime', new Date(item.startTs));
								var limitTimes = (item.startTs + item.minutesLimit * 60 * 1000) - nowTime;
							}
							if (item.endTs) {
								item.endTime = that.COMMON.getDataMethods('getTime', new Date(item.endTs));
							}
							// 未提交试卷但已经超时，和超出试卷截止日期，都不可点击开始考试 
							if ((item.toTs <= nowTime) || limitTimes <= 0) {
								item.startQuiz = false;
								item.endTitle="答题结束";
							}else if(item.fromTs>nowTime){
								item.startQuiz = false;
								item.endTitle="未开始";
							}else {
								item.startQuiz = true;
								if(item.startTs){
									item.startTitle="继续答题";
								}
								if(!item.startTs){
									item.startTitle="开始答题";
								}
							}
							// 判断是已经开考否已经超出限制分钟
							item.ruleConfirmedValue = item.ruleConfirmed;
						});
						that.total = res.data.totalCount;
					})
					.catch(function(error) {
						that.loading = false;
						if (error == "登录失效, 请重新登录!") {
							console.log(error)
						} else {
							that.$alert(error, '提示', {
								confirmButtonText: '确定',
								type: 'error',
								callback: action => {}
							});
						}
					});
			},
			// 点击开始答题
			editClick(index, row) {
				var that = this;
				that.dialogForm = true;
				setTimeout(function() {
					that.$refs.examForm.getDetail(row,that.userId, that.completed);
					if(!row.startTs){
						that.$notify({
							title: '注意',
							message: '考试开始，请在规定时间内完成考试',
							type: 'warning',
							duration: 0
						});
					}
				}, 50)
			},
			// 确定编辑
			submitForm(formName) {
				var that = this;
				that.loading = true;
				if (that.submitProcessing) {
					return;
				}
				that.submitProcessing = true;
				that.$refs[formName].validate((valid) => {
					if (valid) {
						that.$axios.post("/quiz/edit", that.ruleForm)
							.then(res => {
								that.loading = false;
								that.submitProcessing = false;
								that.$notify({
									title: '提示',
									message: '试题编辑成功!',
									type: 'success',
									duration: 2000
								});
								that.handleCurrentChange(that.pageNum);
								that.dialogForm = false;
							})
							.catch(err => {
								that.loading = false;
								that.submitProcessing = false;
								that.$notify({
									title: '失败',
									message: err,
									type: 'error',
									duration: 2000
								});
							})
					} else {
						that.loading = false;
						that.submitProcessing = false;
						return false;
					}
				});
			},
		}
	}
</script>

<style lang='scss' scoped>
	#myQuizSystem {
		background: #f2f2f2;
		height: 100vh;

		/* 主要内容 */
		.mainContent {
			background: #FFFFFF;
			padding: 15px;

			/* 查询 */
			.search {
				.searchButton {
					background: #000000;
					color: #FFFFFF;
				}

				.el-button {
					margin-left: 5px;
				}
			}

			/* 列表 */
			.quizListTable {
				margin: 10px auto;
			}

			/* 分页 */
			.block {
				background: transparent;
			}
		}
	}
</style>
